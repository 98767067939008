import * as React from "react"
import { useEffect } from 'react';
import { Link, graphql } from 'gatsby'
import anime from "animejs";
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Layout from "../../components/layout"

import NewPostsSwiper from '../../components/index/new-posts-swiper'
import MainSlideImageSwiper from '../../components/index/main-slide-image-swiper'
import NewsList from '../../components/index/news-list'
import SightseeingList from '../../components/index/sightseeing-list'
import OnlinebarList from '../../components/index/onlinebar-list'

import kvLogo from "../../images/logo-white.svg"
import kvsubTitle from "../../images/en/kv-sub-ttl.png"

const ttt = "TANOSHIGE"
const ddd = "HIROSHIMA TANOSHIGEは、広島の楽しげなヒト・モノ・コトを日本、そして世界に発信しています。繋がりから新たな交流や企画を生み出していきます。ラジオの親近感とテレビのエンターテイメント、人との繋がり面白いコトを創り出す、まさに“見えるラジオ 繋がるテレビ”をお届けします。"

// markup
const IndexPage = ({ data }) => {

  useEffect(() => {
    setTimeout(function () {
      // Create a timeline with default parameters
      let tl = anime.timeline({
        easing: 'spring',
        duration: 1250
      });

      // Add children
      tl
        .add({
          targets: '#kv-logo',
          bottom: '50%',
        })
        .add({
          targets: '.kv-img',
          opacity: 1,
        })
        .add({
          targets: '#kv-sub-ttl',
          opacity: 1,
        });

    }, 1000)
  })

  return (
    <Layout isHomePage={true} headerClass="bg-default" isLogoOneWhite={false} isLogoTwoWhite={true} en={true}>
      <Seo title={ttt} description={ddd} />
      <main>

        <section id="kv">
          <div id="kv-stage">
            <div id="kv-bottom"><StaticImage src="../../images/kv-bottom.jpg" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" /></div>
            <div className="kv-img">
              <StaticImage src="../../images/kv-full.jpg" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" />
            </div>
            <div className="kv-img-sp">
              <StaticImage src="../../images/en/kv-sp.png" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" />
            </div>
          </div>
          <div id="kv-logo">
            <img src={kvLogo} alt="" />
          </div>
          <div id="kv-sub-ttl" className="en-subttl">
            <img src={kvsubTitle} alt="" />
          </div>

        </section>
        <NewPostsSwiper />
        <section id="top-about">
          <h2><StaticImage src="../../images/en/ttl-content-1st@2x.png" alt="見えるラジオ繋がるテレビ" /></h2>
          <div id="toroku-content"><StaticImage src="../../images/ttl-content-2nd@2x.png" alt="貢藤十六" /></div>
          <p className="inner top-about-note">
            HIROSHIMA TANOSHIGE is transmitting Hiroshima’s joyful people and the things to Japan, and all over the world.&nbsp;&nbsp;<span className="pc-br"></span>
Hiroshima is a world famous city, however still has full of as-yet-unknown charms.&nbsp;&nbsp;<span className="pc-br"></span>
"Hiroshima Online Bar TANOSHIGE" that shows talking about current joyous theme in Hiroshima<span className="pc-br"></span>
is participative contents interacted by Japan’s first-ever ZOOM DJ(?)<span className="pc-br"></span>getting a handle the online-talking together with the topic person each time.&nbsp;&nbsp;<span className="pc-br"></span>
And then, it will cause the new exchanges and plannings or projects.&nbsp;&nbsp;<span className="pc-br"></span>
Radio with sense of affinity and televisions having entertainability, <span className="pc-br"></span>and the connection with people will create something fun and interesting, <span className="pc-br"></span>We exactly will offer and release you all just<span className="pc-br"></span>"visual information radio and TV to lead and connect".
            </p>
        </section>

        <section id="sightseeing">
          <h2><StaticImage src="../../images/ttl-sightseeing@2x.png" alt="SIGHTSEEING" /></h2>
          <MainSlideImageSwiper />
        </section>

        <SightseeingList en={true} />

        <OnlinebarList en={true} />

        <NewsList en={true} />

        <section id="kudo">
          <h2><StaticImage src="../../images/ttl-toroku@2x.png" alt="貢藤十六" /></h2>
          <div className="kudo-content inner">
            <div className="kudo-img">
              <StaticImage src="../../images/toroku-kudo.png" alt="TOROKU KUDO" />
            </div>
            <div className="kudo-note">
              <h3>MC of the program:<br />ZOOM DJ(?) Toroku Kudo</h3>
              <p>
                Height: 178cm<br />The place of birth: Osaka.<br />Born in 1974<br />Star sign: Cancer<br />Blood type: A<br />
                Hobby: Touring, Driving the car, Playing the guitar<br />
Used to live in Australia, by way of an office worker then started to become DJ at the radio station and MC mainly for sport events since 2003. &nbsp;&nbsp;Narration for TV, YouTube movies, TV commercials and recently has experienced as a stage actor.&nbsp;&nbsp;Expanding a wide range of  activities.
              </p>
            </div>
          </div>
          <Link className="link-btn" to="/toroku"><span>Click Here For More</span></Link>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default IndexPage
